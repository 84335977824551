import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { useSettings } from '../../contexts/settings-context';

const UrgentAlert = ({ message, actionText, onActionClick }) => {
  const { settings } = useSettings();
  const isLightTheme = settings.theme === 'light';
  console.log('settings', settings);

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: isLightTheme ? '#FFEBEE' : '#3B1515',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          flex: 1,
          justifyContent: 'center'
        }}
      >
        <WarningIcon
          sx={{
            color: '#FF4444',
            fontSize: 20
          }}
        />
        <Typography
          sx={{
            color: '#FF4444',
            fontSize: '14px',
            fontWeight: 400,
            letterSpacing: '0.15px'
          }}
        >
          {message}
        </Typography>
        <Button
          variant="text"
          sx={{
            color: isLightTheme ? '#000' : '#FFF',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            padding: '4px 12px',
            minWidth: 'auto',
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: isLightTheme ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.1)'
            }
          }}
          onClick={onActionClick}
        >
          {actionText}
        </Button>
      </Box>
    </Box>
  );
};

UrgentAlert.defaultProps = {
  actionText: 'Add Credit Card',
  onActionClick: () => { }
};

UrgentAlert.propTypes = {
  message: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  onActionClick: PropTypes.func
};

export default UrgentAlert;
