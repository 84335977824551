/* eslint-disable max-len */
import React, { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';

import jwtAxios from '../api/jwt-api';
import { handleErrors } from '../services/error-service';
import EtimsService from '../services/etims-service/etims-service';

const MerchantContext = createContext();
const MerchantProvider = ({ children }) => {
  const [merchantData, setMerchantData] = useState(null);
  const [merchantLogo, setMerchantLogo] = useState(null);
  const [etimsDeviceInfo, setEtimsDeviceInfo] = useState(null);
  const [globalTaxRates, setGlobalTaxRates] = useState(null);
  const [merchantUpdateBody, setMerchantUpdateBody] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const loggedInUser = window.localStorage.getItem('user');
  const user = JSON.parse(loggedInUser);

  useEffect(() => {
    setMerchantUpdateBody({
      additionalDiscount: merchantData?.additionalDiscount,
      base64Image: merchantData?.base64Image,
      branchCollectionMappingEnabled: merchantData?.branchCollectionMappingEnabled,
      branchPricingAllowed: merchantData?.branchPricingAllowed,
      businessName: merchantData?.businessName,
      businessTypeId: merchantData?.merchantType?.id,
      city: merchantData?.city,
      countryId: merchantData?.country?.id,
      currencyId: merchantData?.currency?.id,
      customStatusEnabled: merchantData?.customStatusEnabled,
      email: merchantData?.email,
      branches: merchantData?.branches,
      defaultBranchForStockSharing: merchantData?.defaultBranchForStockSharing,
      expressStockUpdateAllowed: merchantData?.expressStockUpdateAllowed,
      integratedWithETIMS: merchantData?.integratedWithETIMS,
      integratedWithTIMS: merchantData?.integratedWithTIMS,
      location: merchantData?.location,
      logoUrl: merchantData?.logoUrl,
      notesSectionOnPOSEnabled: merchantData?.notesSectionOnPOSEnabled,
      notificationEmailDTOS: merchantData?.notificationEmailDTOS,
      phone: merchantData?.phone,
      presentingBeneficiariesOnPOSEnabled: merchantData?.presentingBeneficiariesOnPOSEnabled,
      productNotificationAllowed: merchantData?.productNotificationAllowed,
      shiftActive: merchantData?.shiftActive,
      sendingEmailReceiptAllowed: merchantData?.sendingEmailReceiptAllowed,
      saleInvoiceAllowed: merchantData?.saleInvoiceAllowed,
      stockSharingAllowed: merchantData?.stockSharingAllowed,
      sharingStockBranches: merchantData?.sharingStockBranches,
      timeZone: merchantData?.timeZone,
      usesPurchasePriceExclusive: merchantData?.usesPurchasePriceExclusive,
      waiverIDEnabled: merchantData?.waiverIDEnabled,
      website: merchantData?.website,
      wcorderProcessingEnabled: merchantData?.wcorderProcessingEnabled,
      ...merchantData,
    });
  }, [isLoading, user?.companyId]);

  // eslint-disable-next-line no-unused-vars
  const fetchTaxRates = async (merchantId) => {
    setIsLoading(true);
    try {
      const taxRates = await jwtAxios.get(
        `tax-rates/merchant/${merchantId}?merchantId=${merchantId}&onlyKRAClassTaxes=false`
      );
      setGlobalTaxRates(taxRates.data.data);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const fetchEtimsDeviceInfo = async () => {
    try {
      const etimsService = new EtimsService(user?.companyId, user?.selectedBranch?.id);
      const response = await etimsService.getEtimsDeviceInfo();
      setEtimsDeviceInfo(response);
      console.log('etims device info: ', response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMerchantData = async (merchantId) => {
    if (!merchantId) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await jwtAxios.get(`/merchant/${merchantId}`);
      if (response.data.data) {
        setMerchantData({
          additionalDiscount: response.data.data.additionalDiscount,
          base64Image: response.data.data.base64Image,
          branches: response.data.data.branches,
          branchCollectionMappingEnabled: response.data.data.branchCollectionMappingEnabled,
          branchPricingAllowed: response.data.data.branchPricingAllowed,
          businessTypeId: response.data.data.merchantType.id,
          businessName: response.data.data.businessName,
          city: response.data.data.city,
          country: response.data.data.country,
          currency: response.data.data.currency,
          countryId: response.data.data.country.id,
          currencyId: response.data.data.currency.id,
          email: response.data.data.email,
          expressStockUpdateAllowed: response.data.data.expressStockUpdateAllowed,
          location: response.data.data.location,
          logoUrl: response.data.data.logoUrl,
          notificationEmailDTOS: response.data.data.notificationEmailDTOS,
          phone: response.data.data.phone,
          shiftActive: response.data.data.shiftActive,
          notesSectionOnPOSEnabled: response.data.data.notesSectionOnPOSEnabled,
          presentingBeneficiariesOnPOSEnabled:
            response.data.data.presentingBeneficiariesOnPOSEnabled,
          productNotificationAllowed: response.data.data.productNotificationAllowed,
          waiverIDEnabled: response.data.data.waiverIDEnabled,
          timeZone: response.data.data.timeZone,
          usesPurchasePriceExclusive: response.data.data.usesPurchasePriceExclusive,
          website: response.data.data.website,
          wcorderProcessingEnabled: response.data.data.wcorderProcessingEnabled,
          integratedWithETIMS: response.data.data.integratedWithETIMS,
          integratedWithTIMS: response.data.data.integratedWithTIMS,
          merchantType: response.data.data.merchantType,
          customStatusEnabled: response.data.data.customStatusEnabled,
          sendingEmailReceiptAllowed: response.data.data.sendingEmailReceiptAllowed,
          saleInvoiceAllowed: response.data.data.saleInvoiceAllowed,
          stockSharingAllowed: response.data.data.stockSharingAllowed,
          defaultBranchForStockSharing: response.data.data.defaultBranchForStockSharing,
          sharingStockBranches: response.data.data.sharingStockBranches,
          storeName: response.data.data.storeName,
          ...response.data.data,
        });

        setMerchantLogo(response.data.data.logoUrl ? response.data.data.logoUrl : null);
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };
  const deleteMerchantLogo = async (merchantId) => {
    setIsLoading(true);

    const body = {
      ...merchantUpdateBody,
      base64Image: null,
      logoUrl: null,
    };
    try {
      const response = await jwtAxios.patch(`/merchant/${merchantId}`, body);
      setMerchantData(response.data.data);
      setMerchantLogo(null);
      toast.success('Merchant logo deleted successfully');
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatedMerchantData = async (key, value, id) => {
    const body = {
      ...merchantUpdateBody,
      [key]: value,
    };
    setIsLoading(true);
    try {
      const response = await jwtAxios.patch(`/merchant/${id || merchantData.id}`, body);
      setMerchantData(response.data.data);
      setMerchantLogo(response.data.data?.logoUrl ? response.data.data.logoUrl : null);
      // store the updated merchant data in local storage
      const updatedUser = {
        ...user,
        [key]: response.data.data[key],
      };
      window.localStorage.setItem('user', JSON.stringify(updatedUser));
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      if (error.response) {
        console.log(error.response.status);
        if (
          error.response.data
          && error.response.data.error
          && error.response.data.error[0].description
        ) {
          console.log(error.response.data.error[0].description);
          toast.error(
            `Error updating merchant ${key}: ${error.response.data.error[0].description}`
          );
        } else {
          console.log('Unknown error occurred.');
          toast.error('Error updating merchant: Unknown error occurred.');
        }
      } else {
        console.log('Network error or API endpoint not available.');
        toast.error('Error updating merchant: Network error or API endpoint not available.');
        // add a delay to allow the toast to show before redirecting
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (merchantData?.integratedWithETIMS) {
        await fetchEtimsDeviceInfo();
      }
    };
    fetchData();
  }, [merchantData?.integratedWithETIMS, user?.compamyId, user?.branchId]);

  return (
    <MerchantContext.Provider
      value={{
        merchantData,
        merchantLogo,
        globalTaxRates,
        fetchTaxRates,
        isLoading,
        fetchMerchantData,
        updatedMerchantData,
        setMerchantData,
        setMerchantLogo,
        deleteMerchantLogo,
        merchantUpdateBody,
        etimsDeviceInfo,
      }}
    >
      {children}
    </MerchantContext.Provider>
  );
};

const useMerchantContext = () => {
  const context = useContext(MerchantContext);
  if (context === undefined) {
    throw new Error('useMerchantContext must be used within a MerchantProvider');
  }
  return context;
};

export { MerchantProvider, useMerchantContext };

MerchantProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
