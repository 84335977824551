import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { Receipt } from '@material-ui/icons';
import { Box, Divider, Drawer, IconButton, List } from '@material-ui/core';
import { DashboardSidebarItem } from './dashboard-sidebar-item';
import { CustomCreditCard } from '../icons/custom-credit-card';
import { Scrollbar } from './scrollbar';
import { Cog as CogIcon } from '../icons/cog';
import { User as UserIcon } from '../icons/user';
import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag';
import { CustomCube as CubeIcon } from '../icons/custom-cube';
import { Cash as CashIcon } from '../icons/cash';
import { ChevronLeft as ChevronLeftIcon } from '../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';
import { CustomChartPie as ChartPieIcon } from '../icons/custom-chart-pie';
import { CustomShoppingCart as ShoppingCartIcon } from '../icons/custom-shopping-cart';
import { CustomUsers as UsersIcon } from '../icons/custom-users';
import { Chip as ChipIcon } from '../icons/chip';
import { DocumentText as DocumentTextIcon } from '../icons/document-text';
import { OfficeBuilding as OfficeBuildingIcon } from '../icons/office-building';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Mail } from '../icons/mail';
import MerchantNavigationLogo from './merchant-navigation-logo';
import { useMerchantContext } from '../contexts/merchant-context';
import { Calendar } from '../icons/calendar';

const itemsInternal = [
  {
    icon: ChartPieIcon,
    title: 'Summary',
    items: [
      {
        href: '/summary/analytics',
        title: 'Analytics'
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'User Management',
    items: [
      {
        href: '/users',
        title: 'Users'
      },
      {
        href: 'users/group',
        title: 'User Group'
      }
    ]
  },
  {
    icon: OfficeBuildingIcon,
    title: 'Merchants',
    items: [
      {
        href: '/merchants',
        title: 'List'
      }
    ]
  },
  {
    icon: CustomCreditCard,
    title: 'Billing',
    items: [
      {
        href: '/merchants/billing/accounts',
        title: 'Accounts'
      },
      {
        href: '/merchants/billing/transactions',
        title: 'Transactions'
      },
      {
        href: '/merchants/billing/invoices',
        title: 'Invoices'
      },
      {
        href: '/merchants/billing/bills',
        title: 'Bills'
      },
      {
        href: '/merchants/billing/categories',
        title: 'Categories'
      },
    ]
  },
];

const itemsExternal = [
  {
    icon: ChartPieIcon,
    title: 'Summary',
    items: [
      {
        href: '/summary',
        title: 'Sales'
      },
      {
        href: '/summary/analytics',
        title: 'Analytics'
      },
      {
        href: '/summary/e-commerce',
        title: 'E-Commerce'
      },
      {
        href: '/summary/cashiers-performance',
        title: 'Cashiers\' Performance'
      },
    ]
  },
  {
    icon: ShoppingCartIcon,
    title: 'Sale',
    items: [
      {
        href: '/pos/sales',
        title: 'POS'
      },
      {
        href: '/pos/register',
        title: 'Register'
      },
      {
        href: '/pos/sales-history',
        title: 'Sales History'
      },
      {
        href: '/pos/sales-payments/matched',
        title: 'Sales Payments'
      },
      {
        href: '/pos/ecommerce-orders',
        title: 'E-commerce Orders'
      }
    ]
  },
  // {
  //   icon: Receipt,
  //   title: 'Invoices & Quotation',
  //   items: [
  //     {
  //       href: '/invoices/create',
  //       title: 'Create Invoice'
  //     },
  //     {
  //       href: '/invoices',
  //       title: 'Invoice List'
  //     },
  //     {
  //       href: '/quotation',
  //       title: 'Quotation'
  //     },
  //     {
  //       href: '/quotation/create',
  //       title: 'Create Quote'
  //     }
  //   ]
  // },
  {
    icon: CubeIcon,
    title: 'Products & Services',
    items: [
      {
        href: '/products/list/',
        title: 'Product List'
      },
      {
        href: '/products/services/list',
        title: 'Services List'
      },
      {
        href: '/products/add',
        title: 'Add Product or Service'
      },
      {
        href: '/products/import-products',
        title: 'Import/Export Products'
      },
      {
        href: '/products/category',
        title: 'Categories'
      },
      {
        href: '/products/variations',
        title: 'Variations'
      },
      {
        href: '/products/units',
        title: 'Units'
      },
      {
        href: '/products/brands',
        title: 'Brands'
      },
      {
        href: '/products/custom-fields',
        title: 'Custom Fields'
      },
      {
        href: '/products/print-labels',
        title: 'Print Labels'
      }
    ]
  },
  {
    icon: ShoppingBagIcon,
    title: 'Purchases',
    items: [
      {
        href: '/purchase/list',
        title: 'Purchase List'
      },
      {
        href: '/purchase/return',
        title: 'Purchase Return'
      },
      {
        href: '/purchase/suppliers',
        title: 'Suppliers'
      },
      {
        href: '/purchase/etims',
        title: 'Etims purchases'
      }
    ]
  },
  {
    icon: CashIcon,
    title: 'Expenses',
    items: [
      {
        href: '/expenses/list',
        title: 'List'
      },
      {
        href: '/expenses/categories',
        title: 'Categories'
      }
    ]
  },
  {
    icon: Mail,
    title: 'Bulk Messages',
    items: [
      {
        href: '/messages/bulk-message',
        title: 'Bulk Message'
      },
      {
        href: '/messages/messages-history',
        title: 'Messages History',
      },
    ]
  },
  {
    icon: Calendar,
    title: 'Bookings',
    items: [
      {
        href: '/bookings/list',
        title: 'List'
      },
      {
        href: '/bookings/calendar',
        title: 'Calendar'
      },
      {
        href: '/bookings/settings',
        title: 'Settings'
      },
    ]
  },
  {
    icon: UserIcon,
    title: 'CRM',
    items: [
      {
        href: '/crm/customers/list',
        title: 'Customers'
      },
      {
        href: '/crm/customers/categories',
        title: 'Categories'
      },
      {
        href: '/crm/customers/import-customers',
        title: 'Import Customers'
      },
      {
        href: '/crm/customers/custom-fields',
        title: 'Custom Fields'
      },
      {
        href: '/crm/subscription-plans',
        title: 'Subscription Plans'
      }
    ]
  },
  {
    icon: DocumentTextIcon,
    title: 'Stock Management',
    items: [
      {
        href: '/stock-management/branch-stock-monitoring',
        title: 'Branch Stock Monitoring'
      },
      {
        href: '/stock-management/direct-stock-update',
        title: 'Direct Stock Update'
      },
      {
        href: '/stock-management/transfer',
        title: 'Stock Transfer'
      },
      {
        href: '/stock-management/opening-stock',
        title: 'Import Opening Stock'
      },
      {
        href: '/stock-management/stock-adjustment/',
        title: 'Stock Adjustment'
      },
      {
        href: '/stock-management/branch-stock-take',
        title: 'Branch Stock Take'
      },
      {
        href: '/stock-management/etims-move-list',
        title: 'Etims Move List'
      }
    ]
  },
  {
    icon: ChartBarIcon,
    title: 'Reports',
    items: [
      {
        href: '/reports/sales',
        title: 'Sales Reports'
      },
      {
        href: '/reports/stock',
        title: 'Stock Reports'
      },
      {
        href: '/reports/expenses',
        title: 'Expenses Reports'
      },
      {
        href: '/reports/tax',
        title: 'Tax Reports'
      },
      {
        href: '/reports/standard-reports',
        title: 'Standard Reports'
      },
      {
        href: 'reports/historical-registers-report',
        title: 'Register Reports'
      },
      {
        href: '/reports/price-lists',
        title: 'Price Lists Report'
      },
      {
        href: '/reports/income-statement',
        title: 'Income statement'
      },
      {
        href: '/reports/pfms',
        title: 'PFMS'
      },
    ]
  },
  {
    icon: UsersIcon,
    title: 'Staff & Commissions',
    items: [
      {
        href: '/staff/',
        title: 'Staff List'
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'User Management',
    items: [
      {
        href: '/users/list',
        title: 'Users'
      },
      {
        href: '/users/user/group',
        title: 'User Group'
      }
    ]
  },
  {
    icon: OfficeBuildingIcon,
    title: 'Branch Management',
    items: [
      {
        href: '/branches/list',
        title: 'Branch List'
      },
      {
        href: '/branches/etims-list',
        title: 'Etims Branch List'
      },
      {
        href: '/etims/insurance-list',
        title: 'Etims Insurance List'
      }
    ]
  },
  // {
  //   icon: CustomCreditCardIcon,
  //   title: 'Payments',
  //   href: '/payments'
  // },
  {
    icon: CustomCreditCard,
    title: 'Billing',
    badge: 'NEW',
    href: '/billing'
  },
  {
    icon: ChipIcon,
    title: 'Plugins',
    href: '/plugins'
  },
  {
    icon: CogIcon,
    title: 'Settings',
    items: [
      {
        href: '/settings/pos-settings',
        title: 'POS Settings'
      },
      {
        href: '/settings/bulk-message-settings',
        title: 'Bulk Messaging Settings'
      },
      {
        href: '/settings/notifications',
        title: 'Notifications'
      }
    ]
  }
];

const branchAdmin = [
  {
    icon: ShoppingCartIcon,
    title: 'Sale',
    items: [
      {
        href: '/pos/sales',
        title: 'POS'
      },
      {
        href: '/pos/register',
        title: 'Register'
      },
      {
        href: '/pos/sales-payments/matched',
        title: 'Sales Payments'
      },
      {
        href: '/pos/sales-history',
        title: 'Sales History'
      },
      {
        href: '/pos/ecommerce-orders',
        title: 'E-commerce Orders'
      },
    ]
  },
  {
    icon: CubeIcon,
    title: 'Products & Services',
    items: [
      {
        href: '/products/list/',
        title: 'Product List'
      },
      {
        href: '/products/services/list',
        title: 'Services List'
      },
      {
        href: '/products/print-labels',
        title: 'Print Labels'
      }
    ]
  },
  {
    icon: ShoppingBagIcon,
    title: 'Purchases',
    items: [
      {
        href: '/purchase/list',
        title: 'Purchase List'
      },
      {
        href: '/purchase/return',
        title: 'Purchase Return'
      },
      {
        href: '/purchase/suppliers',
        title: 'Suppliers'
      },
      {
        href: '/purchase/etims',
        title: 'Etims purchases'
      }
    ]
  },
  {
    icon: CashIcon,
    title: 'Expenses',
    items: [
      {
        href: '/expenses/list',
        title: 'List'
      }
    ]
  },
  {
    icon: UserIcon,
    title: 'CRM',
    items: [
      {
        href: '/crm/customers/list',
        title: 'Customers'
      },
      {
        href: '/crm/customers/categories',
        title: 'Categories'
      },
      {
        href: '/crm/customers/import-customers',
        title: 'Import Customers'
      },
      {
        href: '/crm/customers/custom-fields',
        title: 'Custom Fields'
      },
    ]
  },
  {
    icon: Calendar,
    title: 'Bookings',
    items: [
      {
        href: '/bookings/list',
        title: 'List'
      },
      {
        href: '/bookings/calendar',
        title: 'Calendar'
      },
      {
        href: '/bookings/settings',
        title: 'Settings'
      },
    ]
  },
  {
    icon: DocumentTextIcon,
    title: 'Stock Management',
    items: [
      {
        href: '/stock-management/branch-stock-monitoring',
        title: 'Branch Stock Monitoring'
      },
      {
        href: '/stock-management/direct-stock-update',
        title: 'Direct Stock Update'
      },
      {
        href: '/stock-management/transfer',
        title: 'Stock Transfer'
      },
      {
        href: '/stock-management/opening-stock',
        title: 'Opening Stock'
      }
    ]
  },
  {
    icon: CogIcon,
    title: 'Settings',
    items: [
      {
        href: '/settings/pos-settings',
        title: 'POS Settings'
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'Staff & Commissions',
    items: [
      {
        href: '/staff/',
        title: 'Staff List'
      }
    ]
  },
];

const cashier = [
  {
    icon: ShoppingCartIcon,
    title: 'Sale',
    items: [
      {
        href: '/pos/sales',
        title: 'POS'
      },
      {
        href: '/pos/sales-history',
        title: 'Sales History'
      }
    ]
  },
  {
    icon: CubeIcon,
    title: 'Products & Services',
    items: [
      {
        href: '/products/print-labels',
        title: 'Print Labels'
      }
    ]
  },
  {
    icon: UserIcon,
    title: 'CRM',
    items: [
      {
        href: '/crm/customers/list',
        title: 'Customers'
      },
    ]
  },
  {
    icon: Calendar,
    title: 'Bookings',
    items: [
      {
        href: '/bookings/list',
        title: 'List'
      },
      {
        href: '/bookings/calendar',
        title: 'Calendar'
      },
      // {
      //   href: '/bookings/settings',
      //   title: 'Settings'
      // },
    ]
  },
];

const accountant = [
  {
    icon: ChartPieIcon,
    title: 'Summary',
    items: [
      {
        href: '/summary',
        title: 'Sales'
      },
      {
        href: '/summary/analytics',
        title: 'Analytics'
      },
      {
        href: '/summary/e-commerce',
        title: 'E-Commerce'
      },
      {
        href: '/summary/cashiers-performance',
        title: 'Cashiers\' Performance'
      }
    ]
  },
  {
    icon: ShoppingCartIcon,
    title: 'Sale',
    items: [
      {
        href: '/pos/sales-history',
        title: 'Sales History'
      },
      {
        href: '/pos/sales-payments/matched',
        title: 'Sales Payments'
      },
      {
        href: '/pos/ecommerce-orders',
        title: 'E-commerce Orders'
      }
    ]
  },
  {
    icon: ShoppingBagIcon,
    title: 'Purchases',
    items: [
      {
        href: '/purchase/list',
        title: 'Purchase List'
      },
      {
        href: '/purchase/return',
        title: 'Purchase Return'
      }
    ]
  },
  {
    icon: CashIcon,
    title: 'Expenses',
    items: [
      {
        href: '/expenses/list',
        title: 'List'
      }
    ]
  },
  {
    icon: DocumentTextIcon,
    title: 'Stock Management',
    items: [
      {
        href: '/stock-management/branch-stock-monitoring',
        title: 'Branch Stock Monitoring'
      },
      {
        href: '/stock-management/direct-stock-update',
        title: 'Direct Stock Update'
      },
      {
        href: '/stock-management/transfer',
        title: 'Stock Transfer'
      },
      {
        href: '/stock-management/stock-adjustment/',
        title: 'Stock Adjustment'
      },
      {
        href: '/stock-management/branch-stock-take',
        title: 'Branch Stock Take'
      },
      {
        href: '/stock-management/etims-move-list',
        title: 'Etims Move List'
      }
    ]
  },
  {
    icon: ChartBarIcon,
    title: 'Reports',
    items: [
      {
        href: '/reports/sales',
        title: 'Sales Reports'
      },
      {
        href: '/reports/stock',
        title: 'Stock Reports'
      },
      {
        href: '/reports/expenses',
        title: 'Expenses Reports'
      },
      {
        href: '/reports/tax',
        title: 'Tax Reports'
      },
      {
        href: '/reports/standard-reports',
        title: 'Standard Reports'
      },
      {
        href: 'reports/historical-registers-report',
        title: 'Register Reports'
      },
      {
        href: '/reports/price-lists',
        title: 'Price Lists Report'
      },
    ]
  }
];

// const tabs = [
//   {
//     href: `/merchants/${params.merchantId}`,
//     label: 'Summary'
//   },
//   {
//     href: `/merchants/${params.merchantId}/users`,
//     label: 'Users'
//   },
//   {
//     href: `/merchants/${params.merchantId}/brands`,
//     label: 'Brands'
//   },
//   {
//     href: `/merchants/${params.merchantId}/units`,
//     label: 'Units'
//   },
//   {
//     href: `/merchants/${params.merchantId}/tax-rate`,
//     label: 'Tax Rate'
//   },
//   {
//     href: `/merchants/${params.merchantId}/variation`,
//     label: 'Variation'
//   },
//   {
//     href: `/merchants/${params.merchantId}/category`,
//     label: 'Category'
//   },
//   {
//     href: `/merchants/${params.merchantId}/product`,
//     label: 'Products'
//   },
//   {
//     href: `/merchants/${params.merchantId}/product-add`,
//     label: 'Add Product'
//   },
//   {
//     href: `/merchants/${params.merchantId}/supplier`,
//     label: 'Suppliers'
//   },
//   {
//     href: `/merchants/${params.merchantId}/purchase`,
//     label: 'Purchase'
//   },
//   {
//     href: `/merchants/${params.merchantId}/purchase-return`,
//     label: 'Purchase Return'
//   },
//   {
//     href: `/merchants/${params.merchantId}/customers`,
//     label: 'Customers'
//   },
//   {
//     href: `/merchants/${params.merchantId}/discounts`,
//     label: 'Discounts'
//   },
//   {
//     href: `/merchants/${params.merchantId}/stock-transfer`,
//     label: 'Stock Transfer'
//   }
// ];

export const DashboardSidebar = (props) => {
  const { onPin, pinned } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeHref, setActiveHref] = useState('');
  const [hovered, setHovered] = useState(false);
  const { merchantData } = useMerchantContext();
  const [menu, setMenu] = useState([]);

  const loggedInUser = window.localStorage.getItem('user');
  const user = JSON.parse(loggedInUser);

  const handleOpenItem = (item) => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    let menuItems = [];
    if (user.type === 'ExternalType' && user.group === 'Cashier') {
      menuItems = cashier;
    } else if (user.type === 'ExternalType' && user.group === 'Branch Admin') {
      menuItems = branchAdmin;
    } else if (user.type === 'ExternalType' && user.group === 'Merchant Admin') {
      menuItems = itemsExternal;
    } else if (user.type === 'ExternalType' && user.group === 'Accountant') {
      menuItems = accountant;
    } else {
      menuItems = itemsInternal;
    }
    const filterMenuItems = () => {
      // Start with the full menuItems list
      // eslint-disable-next-line no-shadow
      let updatedMenuItems = menuItems;
      // Filter out 'Bookings' if serviceBookingAllowed is false
      if (!merchantData?.serviceBookingAllowed) {
        updatedMenuItems = updatedMenuItems.filter((menuItem) => menuItem.title !== 'Bookings');
      }
      if (!merchantData?.integratedWithETIMS) {
        updatedMenuItems = updatedMenuItems.map((menuItem) => {
          // Check if there are sub-menu items
          if (menuItem.items) {
            // Filter out sub-menu items that start with 'Etims'
            const filteredSubMenuItems = menuItem.items.filter(
              (subMenuItem) => !subMenuItem.title.startsWith('Etims ')
            );
            // Return the menuItem with filtered sub-menu items
            return { ...menuItem, items: filteredSubMenuItems };
          }
          // If no sub-menu items, return the menuItem as it is
          return menuItem;
        });
      }

      if (merchantData?.id !== 144) {
        updatedMenuItems = updatedMenuItems.map((menuItem) => {
          // Check if there are sub-menu items
          if (menuItem.items) {
            // Filter out sub-menu items that start with 'PFMS'
            const filteredSubMenuItems = menuItem.items.filter(
              (subMenuItem) => !subMenuItem.title.startsWith('PFMS')
            );
            // Return the menuItem with filtered sub-menu items
            return { ...menuItem, items: filteredSubMenuItems };
          }
          // If no sub-menu items, return the menuItem as it is
          return menuItem;
        });
      }
      return updatedMenuItems;
    };
    // Filter out 'Billing' if billingEnabled is false
    const finalMenuItems = filterMenuItems();
    setMenu(finalMenuItems);
    // const menuItems = user.type === 'InternalType' ? itemsInternal : itemsExternal;
    finalMenuItems.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname, merchantData]);

  return (
    <Drawer
      open
      sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        onMouseOver: () => { setHovered(true); },
        onMouseLeave: () => { setHovered(false); },
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px)',
          overflowX: 'hidden',
          top: 64,
          transition: 'width 250ms ease-in-out',
          width: pinned ? 270 : 73,
          '& .simplebar-content': {
            height: '100%'
          },
          '&:hover': {
            width: 270,
            '& span, p': {
              display: 'flex'
            }
          }
        }
      }}
    >
      <Scrollbar
        style={{
          display: 'flex',
          flex: 1,
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 200,
            px: 1,
            py: 0,
            mt: 1
          }}
        >
          <MerchantNavigationLogo xs={12} />
          <Divider />
          <List disablePadding>
            {menu.map((item) => (
              <DashboardSidebarItem
                active={activeItem?.title === item.title}
                badge={item.badge}
                activeHref={activeHref}
                key={item.title}
                onOpen={() => handleOpenItem(item)}
                open={openedItem?.title === item.title && (hovered || pinned)}
                pinned={pinned}
                {...item}
              />
            ))}
            {/* {user.type === 'InternalType' ? (
              activeItem && (itemsInternal.map((item) => (
                <DashboardSidebarItem
                  active={activeItem?.title === item.title}
                  activeHref={activeHref}
                  key={item.title}
                  onOpen={() => handleOpenItem(item)}
                  open={openedItem?.title === item.title && (hovered || pinned)}
                  pinned={pinned}
                  {...item}
                />
              )))
            ) : (
              activeItem && (itemsExternal.map((item) => (
                <DashboardSidebarItem
                  active={activeItem?.title === item.title}
                  activeHref={activeHref}
                  key={item.title}
                  onOpen={() => handleOpenItem(item)}
                  open={openedItem?.title === item.title && (hovered || pinned)}
                  pinned={pinned}
                  {...item}
                />
              )))
            )} */}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box sx={{ pt: 1 }}>
            <IconButton onClick={onPin}>
              {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool
};
