/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import { Box, Container, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import gtm from '../lib/gtm';

export const Reports = () => {
  const location = useLocation();
  const loggedInUser = window.localStorage.getItem('user');
  const user = JSON.parse(loggedInUser);

  const tabs = user.type === 'InternalType'
    ? [
      {
        href: '/summary/analytics',
        label: 'Analytics'
      }
    ] : user.type === 'ExternalType' && user.group === 'Branch Admin'
      ? [
        {
          href: '/summary',
          label: 'Sales'
        },
        {
          href: '/summary/e-commerce',
          label: 'E-Commerce'
        }
      ]
      : [
        {
          href: '/summary',
          label: 'Sales'
        },
        {
          href: '/summary/analytics',
          label: 'Analytics'
        },
        {
          href: '/summary/e-commerce',
          label: 'E-Commerce'
        },
        {
          href: '/summary/cashiers-performance',
          label: 'Cashiers\' Performance'
        },
      ];

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              Summary
            </Typography>
          </Box>
          <Tabs
            allowScrollButtonsMobile
            sx={{ mt: 2 }}
            value={tabs.findIndex((tab) => tab.href === location.pathname)}
            variant="scrollable"
          >
            {tabs.map((option) => (
              <Tab
                component={RouterLink}
                key={option.href}
                label={option.label}
                to={option.href}
              />
            ))}
          </Tabs>
          <Divider />
        </Box>
        <Outlet />
      </Container>
    </Box>
  );
};
